<template>
  <Container :visible="true" class="select-address" noConfirm>
    <Form @submit="onSubmit" ref="form">
      <Field
          v-model="form.accountTypeName"
          is-link
          readonly
          required
          :rules="requiredRules"
          name="picker"
          label="账号类型"
          placeholder="点击选择账号类型"
          @click="showAccountType = true"
      />
      <Popup v-model="showAccountType" position="bottom">
        <Picker
            title="选择账号类型"
            show-toolbar
            :columns="accountTypeColumns"
            @confirm="onAccountTypeConfirm"
            @cancel="showAccountType = false"
        />
      </Popup>
      <Field
          v-model="form.name"
          required
          :rules="requiredRules"
          label="汇款人名称"
          placeholder="请输入汇款人名称"
      />
      <Field
          v-if="!form.accountType || form.accountType == '1'"
          v-model="form.relationName"
          is-link
          readonly
          required
          :rules="requiredRules"
          name="picker"
          label="与经销商关系"
          placeholder="点击选择与经销商关系"
          @click="showRelation = true"
      />
      <Popup v-model="showRelation" position="bottom">
        <Picker
            title="选择与经销商关系"
            show-toolbar
            :columns="relationColumns"
            @confirm="onRelationConfirm"
            @cancel="showRelation = false"
        />
      </Popup>
      <Field
          v-model="form.bankNumber"
          required
          :rules="requiredRules"
          label="银行卡号"
          placeholder="请输入银行卡号或者上传银行卡照片"
      />
      <ImageUpload label="" :limit="1" v-model="form.bankUrl" @input="onBank"/>
      <Field
          v-if="!form.accountType || form.accountType == '1'"
          v-model="form.idCard"
          required
          :rules="requiredRules"
          label="身份证号"
          placeholder="请输入身份证号或者上传身份证照片"
      />
      <ImageUpload v-if="!form.accountType || form.accountType == '1'" label="" :limit="1" v-model="form.idCardUrl" @input="onIdCard" />
      <Field
          v-if="type == 'edit'"
          v-model="form.reason"
          required
          :rules="requiredRules"
          label="修改原因"
          placeholder="请输入修改原因"
      />
     

      
     
    </Form>
    <Button size="large" class="submit" @click="onSubmit">保存</Button>
    <div class="loading-container" v-if="isLoading">
      <Loading />
    </div>
  </Container>
</template>

<script>
import Container from "@//components/select/container";
import Button from '@/components/common/button/CustomButton'

import { Form, Field,Picker,Popup,Loading } from 'vant';
import {ocrBankAccount,ocrIdCard,addChildBank} from "@/api/bank";
import {fetchDictList} from "@/api/order";
import ImageUpload from '@/components/common/imgUploadCard/index.vue'


export default {
  components: {
    ImageUpload,
    Container,
    Button,
    Form,
    Field,
    Picker,
    Popup,
    Loading
  },
  data() {
    return {
      isLoading: false,
      type:'add',
      showAccountType:false,
      showRelation:false,
      form:{},
      accountTypeColumns:[],
      relationColumns:[],
      autosize:{
        maxHeight: 100, 
        minHeight: 50
        
      },
      requiredRules: [{
        required: true,
        trigger: 'onBlur'
      }],

    }
  },
  activated() {
    this.fetchAccountType();
    this.fetchRelation();
    this.isLoading=false;
    this.form = {};
    this.form = this.$route.query;
    if (this.form.id) {
      this.type = 'edit';
    } else {
      this.type = 'add';
    }
    
  },
  methods: {
    async fetchAccountType() {
      this.accountTypeColumns = [];
      let response = await fetchDictList({
        "type": 'user_type'
      })
      response.data.forEach(item => {
        this.accountTypeColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },

    async fetchRelation() {
      this.relationColumns = [];
      let response = await fetchDictList({
        "type": 'user_relation'
      })
      response.data.forEach(item => {
        this.relationColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    
    
    onAccountTypeConfirm(value) {
      this.showAccountType = false;
      this.form.accountTypeName = value.text;
      this.form.accountType = value.value;
    },

    onRelationConfirm(value) {
      this.showRelation = false;
      this.form.relationName = value.text;
      this.form.relation = value.value;
    },

    onBank(data) {
      if (!data || data.length <= 0) {
        return;
      }
      this.isLoading = true;
      ocrBankAccount({
        "bankUrl": data
      }).then(response => {
        this.form.bankNumber = response.data.cardNo;
        this.isLoading = false;
      }).catch(err => {
        console.error(err)
        this.$toast('银行卡识别失败')
        this.form.bankNumber = "";
        this.isLoading = false;
      })

    },
    onIdCard(data) {
      if (!data || data.length <= 0) {
        return;
      }
      this.isLoading = true;
      ocrIdCard({
        "idCardUrl": data
      }).then(response => {
        this.form.idCard = response.data.data.face.data.idNumber;
        this.isLoading = false;
      }).catch(err => {
        console.error(err)
        this.$toast('身份证识别失败')
        this.form.idCard = "";
        this.isLoading = false;
      })

    },
    
    async onSubmit() {
      var validate = false;
      await this.$refs.form.validate().then(() => {
        validate = true;
      }).catch(() => {
        // this.$toast.fail('请填写完整信息')
      })


      if (this.form.idCard && this.form.idCard.length != 18) {
        this.$toast('请录入18位身份证号码!')
        return;
      }
      
      if (validate) {
        this.isLoading = true;
        const params = {
          ...this.form,
        };
        delete params.page;
        delete params.addDate;
        delete params.addTime;
        delete params.updDate;
        delete params.updTime;
        delete params.createTime;
        delete params.updateTime;
        await addChildBank(params).then(() => {
          this.isLoading = false;
          this.$router.go(-1);
        }).catch(err => {
          console.log(err)
          this.isLoading = false;
        });
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-address {
  .form {
    padding-bottom: 64px;
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    background-color: #848181;
    opacity:0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>