<template>
  <Container :visible="true" class="select-address" noConfirm>
    <Form @submit="onSubmit" ref="form">
        <Field
            v-model="tranTypeName"
            is-link
            readonly
            required
            :rules="requiredRules"
            name="picker"
            label="运输方式"
            placeholder="点击选择运输方式"
            @click="showTranType = true"
        />
        <Popup v-model="showTranType" position="bottom">
          <Picker
              title="选择运输方式"
              show-toolbar
              :columns="tranTypeColumns"
              @confirm="onTranTypeConfirm"
              @cancel="showTranType = false"
          />
        </Popup>
      <Field
          v-if="form.tranType == '20' || form.tranType == '30'"
          v-model="form.provinceName"
          is-link
          readonly
          required
          :rules="requiredRules"
          name="picker"
          label="省"
          placeholder="点击选择省份"
          @click="showProvince = true"
      />
      <Popup v-model="showProvince" position="bottom">
        <Picker
            title="选择省份"
            show-toolbar
            :columns="provinceColumns"
            @confirm="onProvinceConfirm"
            @cancel="showProvince = false"
        />
      </Popup>
      <Field
          v-if="form.tranType == '20' || form.tranType == '30'"
          v-model="form.cityName"
          is-link
          readonly
          required
          :rules="requiredRules"
          name="picker"
          label="市"
          placeholder="点击选择城市"
          @click="onClickCity"
      />
      <Popup v-model="showCity" position="bottom">
        <Picker
            title="选择城市"
            show-toolbar
            :columns="cityColumns"
            @confirm="onCityConfirm"
            @cancel="showCity = false"
        />
      </Popup>
      <Field
          v-if="form.tranType == '20' || form.tranType == '30'"
          v-model="form.areaName"
          is-link
          readonly
          required
          :rules="requiredRules"
          name="picker"
          label="区县"
          placeholder="点击选择区县"
          @click="onClickArea"
      />
      <Popup v-model="showArea" position="bottom">
        <Picker
            title="选择区县"
            show-toolbar
            :columns="areaColumns"
            @confirm="onAreaConfirm"
            @cancel="showArea = false"
        />
      </Popup>
      <Field
          v-if="form.tranType == '20' || form.tranType == '30'"
          v-model="form.tranAddress"
          rows="1"
          required
          :rules="requiredRules"
          :autosize="autosize"
          label="送货地址"
          placeholder="请输入送货地址"
          type="textarea"
      />

      <Field
          v-if="form.tranType == '10'"
          v-model="form.tranAddress"
          required
          :rules="requiredRules"
          label="收货单位"
          placeholder="请输入收货单位"
      />
      <Field
          v-model="form.tranConsignee"
          required
          :rules="requiredRules"
          label="收货人姓名"
          placeholder="请输入收货人姓名"
      />
      <Field
          v-model="form.tranTel"
          required
          :rules="telRules"
          label="收货人电话"
          type="tel"
          placeholder="请输入收货人电话"
      />

      
      <Field
          v-if="form.tranType == '10'"
          v-model="form.tranAdvent"
          required
          :rules="requiredRules"
          label="到局"
          placeholder="请输入到局"
      />
      <Field
          v-if="form.tranType == '10'"
          v-model="form.tranArrival"
          required
          :rules="requiredRules"
          label="到站"
          placeholder="请输入到站"
      />
      <Field
          v-if="form.tranType == '10'"
          v-model="form.tranBillAddress"
          rows="1"
          required
          :rules="requiredRules"
          :autosize="autosize"
          label="小票邮寄地址"
          placeholder="请输入小票邮寄地址"
          type="textarea"
      />
      <Field
          v-if="form.tranType == '10'"
          v-model="form.tranBillConsignee"
          required
          :rules="requiredRules"
          label="小票收件人"
          placeholder="请输入小票收件人"
      />
      <Field
          v-if="form.tranType == '10'"
          v-model="form.code"
          required
          :rules="requiredRules"
          label="邮编"
          placeholder="请输入邮编"
      />
    </Form>
    <Button size="large" class="submit" @click="onSubmit">保存</Button>
    <div class="loading-container" v-if="isLoading">
      <Loading />
    </div>
  </Container>
</template>

<script>
import Container from "@//components/select/container";
import Button from '@/components/common/button/CustomButton'

import { Form, Field,Picker,Popup,Loading } from 'vant';
import {commonApi} from "@/utils/api";
import {getDealerId} from "@/utils/user";
import {addAddress,editAddress} from "@/api/address";

export default {
  components: {
    Container,
    Button,
    Form,
    Field,
    Picker,
    Popup,
    Loading
  },
  data() {
    return {
      isLoading: false,
      type:'add',
      tranTypeName:null,
      showTranType:false,
      showProvince:false,
      showCity:false,
      showArea:false,
      form:{},
      tranTypeColumns:[
        {text:'铁运',value:'10'},
        {text:'汽运',value:'20'},
        {text:'船运',value:'30'}
      ],
      autosize:{
        maxHeight: 100, 
        minHeight: 50
        
      },
      provinceColumns:[],
      cityColumns:[],
      areaColumns:[],
      requiredRules: [{
        required: true,
        trigger: 'onBlur'
      }],
      // 校验手机号码
      telRules: [{
        required: true,
        trigger: 'onBlur'
      }, {
        // 自定义校验规则
        validator: value => {
          return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
              .test(value)
        },
        message: '请输入正确格式的手机号码',
        trigger: 'onBlur'
      }],

    }
  },
  activated() {
    this.isLoading=false;
    this.fetchProvinceList();
    this.form = {};
    this.form = this.$route.query;
    if (this.form.rowId) {
      this.type = 'edit';
    } else {
      this.type = 'add';
    }
    this.tranTypeColumns.forEach(item => {
      if (item.value == this.form.tranType) {
        this.tranTypeName = item.text;
      }
    });
  },
  methods: {
    async fetchProvinceList() {
      this.provinceColumns = [];
      let response = await commonApi.regionLevel({type: '1'});
      response.data.forEach(item => {
        this.provinceColumns.push({
          "value":item.id,
          "text":item.name
        })
      });
    },
    async fetchCityList(id) {
      this.cityColumns = [];
      let response = await commonApi.regionLevel({type: '2',id:id});
      response.data.forEach(item => {
        this.cityColumns.push({
          "value":item.id,
          "text":item.name
        })
      });
    },
    async fetchAreaList(id) {
      this.areaColumns = [];
      let response = await commonApi.regionLevel({type: '3',id:id});
      response.data.forEach(item => {
        this.areaColumns.push({
          "value":item.id,
          "text":item.name
        })
      });
    },
    onTranTypeConfirm(value) {
      this.showTranType = false;
      this.tranTypeName = value.text;
      this.form.tranType = value.value;
    },
    onProvinceConfirm(value) {
      this.showProvince = false;
      this.form.provinceName = value.text;
      this.form.provinceCode = value.value;
      this.form.cityName = null;
      this.form.cityCode = null;
      this.form.areaName = null;
      this.form.areaCode = null;
      this.fetchCityList(value.value);
    },

    onClickCity() {
      if (null == this.form.provinceName || undefined == this.form.provinceName || '' == this.form.provinceName) {
        return this.$toast('请选择省份');
      }
      this.showCity = true;
    },
    onCityConfirm(value) {
      this.showCity = false;
      this.form.cityName = value.text;
      this.form.cityCode = value.value;
      this.form.areaName = null;
      this.form.areaCode = null;
      this.fetchAreaList(value.value);
    },

    onClickArea() {
      if (null == this.form.cityName || undefined == this.form.cityName || '' == this.form.cityName) {
        return this.$toast('请选择城市');
      }
      this.showArea = true;
    },
    onAreaConfirm(value) {
      this.showArea = false;
      this.form.areaName = value.text;
      this.form.areaCode = value.value;
    },
    async onSubmit() {
      var validate = false;
      await this.$refs.form.validate().then(() => {
        validate = true;
      }).catch(() => {
        // this.$toast.fail('请填写完整信息')
      })
      
      if (validate) {
        this.isLoading = true;
        const params = {
          salesId: getDealerId(),
          ...this.form,
        };
        delete params.page;
        delete params.addDate;
        delete params.addTime;
        delete params.updDate;
        delete params.updTime;
        delete params.createTime;
        delete params.updateTime;
        let response;
        if (this.type == 'edit') {
           response = await editAddress(params);
        } else {
           response = await addAddress(params);
        }
        if (response.status == 0) {
          this.isLoading = false;
          this.$router.go(-1);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-address {
  .form {
    padding-bottom: 64px;
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    background-color: #848181;
    opacity:0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>