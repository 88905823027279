<template>
  <div>
    <div class="bank">
      <HeaderNavigation label="" icon=""   />
     
      <Card class="card" v-for="(item, index) in list" :key="index">
        <div class="label-list-container">
          <div class="label-value-container" >
            <div class="label">版本</div>
            <div class="value">{{item.version}}</div>
          </div>
          <div class="label-value-container" >
            <div class="label">汇款人名称</div>
            <div class="value">{{item.name}}</div>
          </div>
          <div class="label-value-container" >
            <div class="label">银行卡号</div>
            <div class="value">{{item.bankNumber}}</div>
          </div>
          <div class="label-value-container" v-if="item.accountType == '1'">
            <div class="label">身份证号</div>
            <div class="value">{{item.idCard}}</div>
          </div>
          <div class="label-value-container" v-if="item.accountType == '1'">
            <div class="label">与经销商关系</div>
            <div class="value">{{item.relationName}}</div>
          </div>
          <div class="label-value-container" >
            <div class="label">账号类型</div>
            <div class="value">{{item.accountTypeName}}</div>
          </div>
          <div class="label-value-container" >
            <div class="label">修改时间</div>
            <div class="value">{{item.createTime}}</div>
          </div>
        </div>
      </Card>

      <span slot="no-more" v-if="!list || list.length <= 0">
          暂无更多
      </span>
      
    </div>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import { editList } from "@/api/bank";

import Card from "@/components/common/card/whiteCard.vue"



export default {
  components: {
    HeaderNavigation,
    Card,
  },
  data() {
    return {
      id: null,
      list: [],
    };
  },
  mounted() {
  },
  activated() {
    this.id = this.$route.query.id;
    this.search();
  },
  methods: {
    async search() {
      const response = await editList({
        "bankId" : this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      });
      this.list = response.data;
    },
   
  },
};
</script>
<style lang="scss" scoped>
.bank {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .card {
    margin-top: 5px;
    padding: 2px 10px;
    .label-list-container {
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .label-value-container {
      width: 100%;
      height: 20px;
      display: flex;
      //align-items: center;
      font-size: 14px;
      &:not(:last-child){
        margin-bottom: 6px;
      }
      .label {
        color: #818181;
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }
      .value {
        color: #2C2C2C;
        margin-left: 6px;
        flex:1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
      
    }

    .menu {
      padding-top: 4px;
      display: flex;
      justify-content: space-around;
      .edit-icon {
        width: 20px;
        height: 18px;
        padding-right: 5px;
      }

      .del-icon {
        width: 25px;
        height: 18px;
        padding-right: 5px;

      }
    }
    
  }
  
}
</style>
