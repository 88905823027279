<template>
  <div class="container">
    <div class="label-limit-container" v-if="label && limit > 1">
      <div class="label"><div class="required" v-if="required">*</div>{{label}}</div>
      <div class="limit">{{images.length}}/{{limit}}</div>
    </div>
    <div class="image-viewer-container">
      <ImageViewer :upload="true" :limit="limit" @changeImg="handleChangeImg"/>
    </div>
  </div>
</template>

<script>
import ImageViewer from '@/components/common/imageViewer/index.vue'

export default {
  components: {
    ImageViewer,
  },
  props: {
    value: {},
    label: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 8
    },
    required: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      images: []
    }
  },
  methods: {
    handleChangeImg(images) {
      this.images = images
      this.$emit('input', images)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 24px 19px;
  background: white;
  .label-limit-container {
    width: 100%;
    height: 20px;
    margin-bottom: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .required {
      font-size: 14px;
      color: #cd001f;
      margin-right: 4px;
    }
    .label {
      display: flex;
      font-size: 14px;
      color: #91939A;
    }
    .limit {
      font-size: 12px;
      color: #E4E4E4;
    }
  }
}
</style>