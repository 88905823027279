<template>
  <div>
    <Boat visible v-if="isShow" :tranMode="tranMode" :key="new Date().getTime()"/>
  </div>
</template>

<script>
import Boat from '@/components/select/address/boat';
export default {
  components: {
    Boat
  },
  data() {
    return {
      tranMode: '',
      isShow: false,
    }
  },
  activated() {
    this.tranMode = this.$route.query.tranMode
    this.isShow = true;
  }
}
</script>